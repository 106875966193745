@font-face {
  font-family: 'Geoform';
  src: url('../../assets/font/Geoform.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geoform-Bold';
  src: url('../../assets/font/Geoform-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Geoform';

}

.first-lab {
  width: 100%;
  height: 100vh;
  background-color: #1A1A2E;
  display: flex;
  justify-content: center;
}

.second-lab,
.third-lab,
.four-lab {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}



.third-lab .container {
  color: #edf2f7;
}

.first-container,
.second-container {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 0;
  border-radius: 1.5rem;


}

.first-container {
  background-color: #ffffff;
  box-shadow: 0px 10px 23px #bebebe;
  min-height: 340px;

}

.sub-container-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-container {
  /* margin: 50px 0; */
  position: relative;

  height: auto;
  min-height: 340px;
  padding: 10px;
  border-radius: 1.5rem;
  box-shadow: 0px 10px 23px #bebebe;
  background-color: #ffffff;
}


.main-title {
  font-size: 90px !important;
  text-align: center;
}

.sub-title {
  /* width: 78%; */
  font-size: 36px !important;
  text-align: center;

}

.sub-title-zh{
  width: 78%;
}

.sub-title-en{
  width: 62%;
}

@media (max-width: 768px) {
  .sub-title-zh, .sub-title-en{
    width: 100%;
  }
}

.sub-title-common {
  font-size: 26px;
  font-family: "Geoform-Bold";
  line-height: 36px;
  position: relative;
  /* 确保可以相对定位 */
  padding-bottom: 0.5rem;
  /* 为文本和虚线之间留出空间 */
}

.industry-detail {
  text-align: left;
  /* 左对齐 */
  margin-top: 70px;
}

.detail-text {
  font-size: 20px;
  line-height: 25px;
  position: relative;
  /* 使伪元素绝对定位 */
  padding-left: 30px;
  /* 为星号留出空间 */
  padding-bottom: 10px;
}

.detail-text::before {
  content: "⭐";
  /* 添加星号 */
  position: absolute;
  /* 绝对定位 */
  left: 0;
  /* 左侧对齐 */
}

.sub-title-common::after {
  content: "";
  /* 创建一个伪元素 */
  display: block;
  /* 使其成为块级元素 */
  width: 100%;
  /* 虚线宽度和文本相同 */
  border-bottom: 1px dashed #BBBBBB;
  /* 设置虚线样式 */
  position: absolute;
  /* 绝对定位 */
  left: 0;
  /* 左对齐 */
  bottom: 0;
  /* 底部对齐 */
}

.arrow-down {
  margin-top: 150px;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 50px;
  height: 50px;
  background-image: url(../img/mul-arrow.svg);
  background-position: center;
}

.hand-icon {
  background-repeat: no-repeat;
  object-fit: cover;
  width: 50px;
  height: 50px;
  background-image: url(../img/hand.svg);
  background-position: center;
}

.pointer-arrow {
  position: relative;
  display: inline-block;
  /* 或者 block，根据需要选择 */
  cursor: pointer;
  /* 鼠标悬停时显示手型光标 */
  font-size: 12px;
  margin-left: 10px;
  color: #5E5E9E;
  text-decoration: underline;
}

.pointer-arrow::after {
  content: '';
  /* 确保伪元素有内容 */
  position: absolute;
  /* 绝对定位 */
  width: 20px;
  /* 根据需要调整箭头图标的宽度 */
  height: 20px;
  /* 根据需要调整箭头图标的高度 */
  background-image: url(../img/arrow.svg);
  /* 箭头图标路径 */
  background-repeat: no-repeat;
  /* 不重复 */
  background-size: contain;
  /* 确保图标适应容器 */
  background-position: center;
  /* 居中显示图标 */
  top: 50%;
  /* 垂直居中 */
  left: 100%;
  /* 在文本右侧 */
  transform: translateY(-50%);
  /* 垂直居中调整 */
}

.close-btn {
  margin-right: 22px;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 35px;
  height: 35px;
  background-image: url(../img/close.svg);
  background-position: center;
  cursor: pointer;
}

/* .digital-intro{
      margin-top: 110px;
  } */

.digital-intro-title {
  font-size: 40px;
  color: #1A1A2E;
  font-weight: 700;
  line-height: 24px;
}

.digital-intro-subtitle {
  font-size: 20px;
  color: #5E5E9E;
  line-height: 26px;
}

.digital-bg {
  min-height: 175px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "Geoform-Bold";
  line-height: 30px;
  border-radius: 0px 30px 0px 30px;
  position: relative;
  /* 使伪元素绝对定位 */
  overflow: hidden;
  /* 隐藏超出部分 */
  background-size: cover;
}

@media (min-width: 768.1px) {
  .third-lab {
    margin-top: 50px;
  }

  .include-div {
    position: relative;
    top: 25px;
  }

  .digital-bg::after {
    content: '';
    /* 必须定义内容以显示伪元素 */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色覆盖层 */
    opacity: 0;
    /* 初始不显示 */
    transition: opacity 0.3s;
    /* 添加过渡效果 */
  }

  .digital-bg:hover::after {
    opacity: 1;
    /* 悬停时显示覆盖层 */
  }
}

.industry-title {
  position: relative;
  /* 确保文字在覆盖层上 */
  z-index: 1;
  /* 提高文字的层级 */
}

.insurance-industry {
  background-image: url(../../assets/img/digital-info/Insurance-Industry.png);
}

.marketing-services-industry {
  background-image: url(../../assets/img/digital-info/Marketing-Services-Industry.png);
}

.manufacturing-industry {
  background-image: url(../../assets/img/digital-info/Manufacturing-Industry.png);
}

.financial-services-industry {
  background-image: url(../../assets/img/digital-info/Financial-Services-Industry.png);
}

.e-commerce-industry {
  background-image: url(../../assets/img/digital-info/E-commerce-Industry.png);
}


@media (max-width: 768px) {
  .include-div {
    margin-bottom: 40px;
  }

  .first-container,
  .second-container {
    width: 92%;
  }

  .sub-container {
    margin: 0;
    min-height: 230px;
  }

  .digital-intro {
    margin-top: 50px;
  }

  .close-btn {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .hover-details {
    display: none;
  }

  .second-lab,
  .third-lab {
    padding: 15px;
  }

  .sub-title-common {
    font-size: 20px;
    line-height: 25px;
    flex-direction: column;
    align-items: center !important;
  }

  .hand-icon {
    background-image: url(../img/hand-down.svg);
  }

  .main-title {
    font-size: 60px !important;
    /* 手机端字体大小 */
  }

  .sub-title {
    font-size: 18px !important;
  }

  .digital-intro-title {
    font-size: 28px;
  }

  .digital-bg {
    height: 360px;
    width: auto;
    display: flex;
    object-fit: cover;
    background-repeat: no-repeat;

  }

  .detail-text {
    font-size: 12px;
    padding-left: 20px;
  }

  .digital-intro-subtitle {
    line-height: 32px;
  }
}


@media (min-width: 768.1px) {
  .hover-details-mobile {
    display: none;
  }

  .second-lab,
  .third-lab {
    min-height: 80vh;
  }

  .container {
    background-color: #1A1A2E !important;
  }
}


.container {
  margin: 6em auto;
  /* width: 65%; */
  background: var(--card-clr);
  padding: 3.5em;
  box-shadow: 0px 36px 23px #0f0f24;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  align-items: flex-start;
}

.container .basic,
.container .pro {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 550px; */
  align-items: flex-start;

}

.container .basic{
  padding-right: 3.5em;
}
.container .pro{
  padding-left: 3.5em;
}



.container .basic {
  border-right: 1px solid white;
}

.container h2 {
  font-size: 2.25rem;
}

.container h4 {
  font-size: 1.5rem;
  margin-top: 1em;
}

.container h6 {
  font-size: 1rem;
}

.price-div {
  display: flex;
}

.price-unit-div {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}




.container ul {
  font-size: 1.125rem;
  /* margin-top: 2em; */
  /* margin-left: 1em; */
  line-height: 2rem;
}

.container h3,
.container h6 {
  text-align: left;
}

.container h3 {
  margin-top: 15px;
}

.container .basic-btn,
.container .pro-btn {
  /* width: 200px; */
  border: none;
  padding: 0.8em 1.5em;
  margin: 2em 0 0 0;
  color: white;
  border: 1px solid #cf8128;
  font-size: 1.125rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #cf8128;
  /* position: absolute;
  bottom: 200px; */
}


.container .basic-btn:hover,
.container .pro-btn:hover {
  background-color: #e09a3a;
  /* 更改为较浅的背景色 */
  border-color: #e09a3a;
  /* 更改边框颜色 */
}

.basic-ul,
.pro-ul {
  list-style-type: none;
  /* 去掉默认圆点 */
  padding: 0;
  /* 移除内边距 */
  margin: 0;
  /* 移除外边距 */
}

.basic-li,
.pro-li,
.benefit-li,
.fee-li {
  font-size: 16px;
  text-align: left;
  position: relative;
  /* 为伪元素定位 */
  padding-left: 20px;
  /* 为左侧留出空间 */
}

.plan-price {
  color: #70A288;
  text-align: left;
  position: relative;
  /* padding-left: 35px;  */
}

.info-div {
  border-top: solid 1px rgb(229 231 235);
  padding: 20px 0;
}





.basic-li::before,
.pro-li::before {
  content: '';
  /* 伪元素内容为空 */
  position: absolute;
  /* 绝对定位 */
  left: 0;
  /* 距离左侧 */
  top: 50%;
  /* 垂直居中 */
  transform: translateY(-50%);
  /* 精确居中 */
  width: 12px;
  /* 图标宽度 */
  height: 12px;
  /* 图标高度 */
  background-image: url('../../assets/img/right.svg');
  /* 使用 SVG 图标 */
  background-size: contain;
  /* 适应容器 */
  background-repeat: no-repeat;
  /* 不重复 */
}

.fee-li::before {
  content: '';
  /* 伪元素内容为空 */
  position: absolute;
  /* 绝对定位 */
  left: 0;
  /* 距离左侧 */
  top: 50%;
  /* 垂直居中 */
  transform: translateY(-50%);
  /* 精确居中 */
  width: 20px;
  /* 图标宽度 */
  height: 20px;
  /* 图标高度 */
  background-image: url('../../assets/img/right-green.svg');
  /* 使用 SVG 图标 */
  background-size: contain;
  /* 适应容器 */
  background-repeat: no-repeat;
  /* 不重复 */
}

.benefit-li::before {
  content: '';
  /* 伪元素内容为空 */
  position: absolute;
  /* 绝对定位 */
  left: 0;
  /* 距离左侧 */
  top: 50%;
  /* 垂直居中 */
  transform: translateY(-50%);
  /* 精确居中 */
  width: 20px;
  /* 图标宽度 */
  height: 20px;
  /* 图标高度 */
  background-image: url('../../assets/img/right-orange.svg');
  /* 使用 SVG 图标 */
  background-size: contain;
  /* 适应容器 */
  background-repeat: no-repeat;
  /* 不重复 */
}

/* .plan-price::before{
  content: ''; 
    position: absolute; 
    left: 0;
    top: 50%; 
    transform: translateY(-50%); 
    width: 30px; 
    height: 30px; 
    background-image: url('../../assets/img/price-dollar.svg');
    background-size: contain; 
    background-repeat: no-repeat; 
} */





.divider-line {
  margin-top: 50px;
  border-top: solid 1px #BBBBBB;
}

@media (max-width: 768px) {
  .container {
    box-shadow: none;
  }

  .container .basic {
    border-right: none;
  }

  .container {
    flex-direction: column;
    width: 100%;
  }

  .container .basic,
  .container .pro {
    padding: 1em;
    width: 100%;
    background-color: #1A1A2E;
    box-shadow: 0px 36px 23px #0f0f24;
    border-radius: 10px;
    height: 100%;
  }

  .container .pro {
    margin-top: 60px;
  }

  .container {
    padding: 0 !important
  }
}



.industry-detail-wrap .item {
  padding: 20px 10px;
  height: 192px;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  background-repeat: no-repeat;
  background-size: cover;
}

.industry-detail-wrap .card-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.industry-detail-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}



.industry-detail-wrap .item .card-inner {

  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.item.selected {
  border: 1px solid #a6a7a8;
  /* 蓝色边框 */
  box-shadow: 0 0 10px rgba(65, 65, 66, 0.5);
  /* 发光效果 */
  transform: scale(1.02);
  /* 轻微放大 */
  transition: all 0.3s ease;
}


@media (max-width: 768px) {
  .industry-detail-wrap .item:hover .card-inner {
    transform: rotateY(180deg);
  }

  .industry-detail-wrap .item:hover .industry-title {
    display: none;
  }
}

.industry-detail-wrap .card {

  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.industry-detail-wrap .front,
.industry-detail-wrap .back {
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}


.industry-detail-wrap .back {
  transform: rotateY(180deg);
}


.industry-detail-wrap .card-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  word-break: break-word;
}

.industry-detail-wrap .item-content {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}