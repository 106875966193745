.PhoneInputInput{
    height: 40px;
}

.ck-editor__editable {
    min-height: 12rem;
}

.blog-editor .ck-editor__editable {
    min-height: 20rem;
}

/* 为小屏幕调整 */

@media only screen and (max-width: 600px) {
    .responsive-video iframe {
        width: 100%;
        min-height: 220px;
        height: auto;
    }
    .react-select-4-placeholder{
        width: 120px;
    }
}

@media only screen and (min-width: 1280px) {
    .react-select-4-placeholder{
        width: 180px;
    }
}

.ck.ck-dropdown .ck-dropdown__arrow {
    z-index: 0 !important;
}

.ck-editor__editable ul,
.ck-editor__editable ol,
.chakra-text ul,
.chakra-text ol {
    padding-left: 25px !important;
}

h1 {
    font-size: 2em !important;
    font-weight: bold !important;
}

h2 {
    font-size: 1.5em;
    font-weight: bold !important;
}

h3 {
    font-size: 1.17em;
    font-weight: bold !important;
}

h4 {
    font-size: 1em;
    font-weight: bold !important;
}

.type-tag {
    border-radius: 6px;
    padding: 5px 8px;
    margin: 10px 0 0 5px;
    &.type-2 {
        background: rgba(255, 171, 0, 0.16);
        color: rgb(183, 110, 0);
    }
    &.type-1 {
        background: rgba(0, 184, 217, 0.16);
        color: rgb(0, 108, 156);
    }
    &.type-0 {
        background: rgba(142, 51, 255, 0.16);
        color: rgb(81, 25, 183);
    }
}

.border-input {
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    padding: 8px 12px;
     >input {
        width: 100%;
     }
}

.border-div {
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    padding: 8px 12px;
}